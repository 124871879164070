<div *ngIf="((this.environments | async)?.length === 0)" class="main-screen no-environments">
  <div class="toolbar">
    <div class="header">
      <span class="logo">Environments</span>
    </div>
    <span class="toolbar-spacer"></span>
    <div class="action-buttons-main">
      <img id="help-action" class="action-button" matTooltip="Request help" (click)="sendEmail('general')"
        src="./assets/icon-help.svg" />
      <img id="create-environment-action" class="action-button" matTooltip="Create a new environment"
        (click)="showCreateEnvironmentDialog()" src="./assets/icon-add-new.svg" />

    </div>

     <div class="action-buttons-main kebab">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="showCreateEnvironmentDialog()">
          <img class="action-button" src="./assets/icon-add-new.svg" />
          <span>Create environment</span>
        </button>
        <button mat-menu-item (click)="sendEmail('general')">
          <img class="action-button" src="./assets/icon-help.svg" />
          <span>Request help</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="action-in-progress">
    <div *ngIf="isLoadingResults">
      <mat-progress-bar mode="indeterminate" class="progress-bar" color="accent"></mat-progress-bar>
    </div>
  </div>
  <div class="main-area no-environments">
    <div *ngIf="!isLoadingResults" class="centered-content">
      <div class="no-env-message">
        <img class="logo" src="./assets/teradata-no-env-logo.svg">
        <div class="main-message">No environment</div>
        <div class="hint">Create an environment to try out ClearScape Analytics.</div>
        <button class="secondary-create-button" mat-flat-button color="primary"
          (click)="showCreateEnvironmentDialog()">
          <span class="mat-button-text">Create</span>
           <img id="create-environment-action" src="./assets/icon-add-new-white.svg" />
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!((this.environments | async)?.length === 0)" class="main-area row">
  <div class="environment-list-column column">
    <div class="environment-list-header toolbar">
      <div class="header">
        <span class="logo">Environments</span>
      </div>
      <span class="toolbar-spacer"></span>
      <div class="action-buttons-main">
        <button id="create-environment-action" mat-icon-button matTooltip="Request help" (click)="sendEmail('general')">
          <mat-icon>help</mat-icon>
        </button>
        <button id="create-environment-action"  mat-icon-button matTooltip="{{((this.user|async)!.envCount >= (this.profileType|async)!.maxMachines)?'Maximum environments reached':'Create a new environment'}}"
          disabled="{{(this.user|async)!.envCount >= (this.profileType|async)!.maxMachines}}" 
         (click)="showCreateEnvironmentDialog()">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="action-buttons-main kebab">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="showCreateEnvironmentDialog()">
            <img class="action-button" src="./assets/icon-add-new.svg" />
            <span>Create environment</span>
          </button>
          <button mat-menu-item (click)="sendEmail('general')">
            <img class="action-button" src="./assets/icon-help.svg" />
            <span>Request help</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="action-in-progress"></div>
    <div class="enviornment-list-container">
      <div class="environment-list" *ngFor="let environment of (this.environments | async)">
        <div class="environment" [class.selected]="this.selectedEnvironment!.name == environment.name"
          (click)="this.selectedEnvironment = environment">
          <div class="environment-info row">
            <div class="environment-name"
              [class.environment-name-active]="this.selectedEnvironment!.name == environment.name">
              {{environment.name}}</div>
            <div class="environment-region">{{environment.region}}</div>
            <div class="environment-region">{{environment.createDate | daysRemaining }} days remaining</div>
            <div class="environment-state-container">
              <div class="environment-state-container-icon">
                <img *ngIf="environment.state === 'STOPPED'" class="state-icon state-icon-stopped"
                  src="./assets/state-stopped.svg" />
                <img *ngIf="environment.state === 'RUNNING'" class="state-icon state-icon-running"
                  src="./assets/state-running.svg" />
                <img *ngIf="['TERMINATED', 'FAILED'].includes(environment.state)" class="state-icon state-icon-terminated"
                  src="./assets/state-terminated.svg" />
                <mat-progress-spinner
                  *ngIf="['PROVISIONING', 'INITIALIZING', 'STARTING', 'STOPPING', 'REPAIRING', 'TERMINATING'].includes(environment.state)"
                  class="progress-spinner" color="accent" mode="indeterminate" value="100" diameter="14">
                </mat-progress-spinner>
              </div>
              <div [ngClass]="['environment-state', environment.state.toLowerCase()]">{{environment.state}}</div>
            </div>
          </div>
          <div class="kebab row">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [disabled]="environment.state !== 'STOPPED'"
                (click)="startEnvironment(environment.name)">
                <img class="action-button" src="./assets/icon-start.svg" />
                <span>Start</span>
              </button>
              <button mat-menu-item [disabled]="environment.state !== 'RUNNING'"
                (click)="stopEnvironment(environment.name)">
                <img class="action-button" src="./assets/icon-stop.svg" />
                <span>Stop</span>
              </button>
              <button mat-menu-item
                [disabled]="environment.state !== 'RUNNING' && environment.state !== 'STOPPED' && environment.state !== 'TERMINATED' && environment.state !== 'FAILED'"
                (click)="deleteEnvironment(environment.name)">
                <img class="action-button" src="./assets/icon-delete.svg" />
                <span>Delete</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
      <div class="enviornment-list-spacer"></div>
      <div class="credits-info-container" *ngIf="(this.consumption|async)?.currentConsumption">
        <div class="credits-info" > 
          <span>{{(this.consumption|async)?.currentConsumption}} of {{(this.consumption|async)?.maxCredits}} ({{(this.consumption|async)?.percentage| number : '1.0-2'}}%) credits used</span>
          <mat-progress-bar [ngClass]="{'error':((this.consumption|async)!.percentage)>=100}" color="{{(((this.consumption|async)!.percentage) < 80)?'accent':'warn'}}" mode="determinate" value="{{(this.consumption|async)?.percentage}}"></mat-progress-bar>
          <span>Your credits reset on {{(this.consumption|async)?.resetDate | date:'YYYY-MM-dd'}}</span>
        </div>
        <!-- <mat-icon>info_outline</mat-icon> -->
      </div>
      <!-- <div class="utilization-widget-container" *ngIf="user | async as userObj">
        <app-utilization-widget [utilization]="userObj.utilization"></app-utilization-widget>
      </div> -->
    </div>
  </div>
  <div class="environment-details-column column"
    *ngIf="this.selectedEnvironment as environment">
    <div class="environment-details-header">
      <div class="header toolbar">
        <div class="environment-details-header-title">{{environment.name}}</div>
        <span class="toolbar-spacer"></span>
        <div class="action-buttons">
          <img id="start-action" class="action-button" matTooltip="Start environment"
            [class.disabled-button]="environment.state !== 'STOPPED'"
            (click)="environment.state === 'STOPPED' && startEnvironment(environment.name)"
            src="./assets/icon-start.svg" />
          <img id="stop-action" class="action-button" matTooltip="Stop environment"
            [class.disabled-button]="environment.state !== 'RUNNING'"
            (click)="environment.state === 'RUNNING' && stopEnvironment(environment.name)"
            src="./assets/icon-stop.svg" />
          <img id="delete-action" class="action-button" matTooltip="Delete environment"
            [class.disabled-button]="environment.state !== 'RUNNING' && environment.state !== 'STOPPED' && environment.state !== 'TERMINATED' && environment.state !== 'FAILED'"
            (click)="(environment.state === 'RUNNING' || environment.state === 'STOPPED' || environment.state === 'TERMINATED' || environment.state === 'FAILED') && deleteEnvironment(environment.name)"
            src="./assets/icon-delete.svg" />
        </div>
      </div>
    </div>
    <div class="action-in-progress">
      <div *ngIf="isLoadingResults">
        <mat-progress-bar mode="indeterminate" class="progress-bar" color="accent"></mat-progress-bar>
      </div>
    </div>
    <div class="environment-details">
      <div *ngIf="environment.state === 'INITIALIZING'" class="centered-content">
        <div class="no-env-message">
          <div class="logo">
            <div class="logo-inner">
              <div class="central-spinner">
                <mat-progress-spinner class="example-margin" color="accent" mode="indeterminate" diameter="32">
                </mat-progress-spinner>
              </div>
            </div>
          </div>
          <div class="main-message">{{environment.name}} is initializing</div>
          <div class="hint">It will take about 3 minutes.</div>
          <!-- <button class="secondary-create-button" mat-flat-button color="primary">Take a tour</button> -->
        </div>
      </div>
      <div class="environment-details-container" *ngIf="environment.state !== 'INITIALIZING'">
        <div class="notifications" *ngFor="let notification of notifications | async">
          <div [ngClass]="['notification', 'notification-type-' + notification.type.toLowerCase()]"
            *ngIf="notification.environment == environment.name">
            <mat-icon class="notification-icon">{{notificationTypeIcons.get(notification.type)}}</mat-icon>
            <div class="notification-body">
              <div class="middle-content">
                <div [ngClass]="['notification-title', 'notification-title-type-' + notification.type]">
                  {{notification.title}}</div>
                <div [ngClass]="['notification-text', 'notification-text-type-' + notification.type]">
                  {{this.dateService.convertUTCToLocalInMessage(notification.text)}}</div>
              </div>
            </div>
            <span class="toolbar-spacer"></span>
            <div class="notification-delete">
              <button mat-button (click)="startEnvironmentAndDismiss(environment,notification)" *ngIf="notification.suggestedAction==='START' && environment.state === 'STOPPED'">Start environment</button>
              <button mat-button (click)="requestHelpAndDismiss(notification)" *ngIf="notification.suggestedAction==='HELP'">Request help</button>
              <button mat-button (click)="createEnvironmentAndDismiss(notification)" *ngIf="notification.suggestedAction==='CREATE_NEW'">Create new environment</button>
              <img src="./assets/icon-close.svg" (click)="deleteNotification(notification)" />
            </div>
            
          </div>
        </div>
        <div class="demo-cta">
          <div class="demo-title">Run dozens of guided demos in Jupyter notebooks</div>
          <div class="demo-verbiage">Explore ClearScape Analytics running on Vantage with dozens of demos across retail,
            finance, telco and other industries. Learn how to do AI/ML, temporal, geospatial, time series analysis.
            Connect or download data from cloud storage.</div>
          <div class="demo-button">
            <button class="secondary-create-button" [disabled]="environment.state !== 'RUNNING'" mat-flat-button
              color="primary" (click)="goToDemos()">
              <span class="mat-button-text">Run demos</span>
              <fa-icon [icon]="faExternalLink"></fa-icon></button>
            <span *ngIf="environment.state !== 'RUNNING'">The environment must be running to use demos. </span>
            <button *ngIf="environment.state === 'STOPPED'" class="secondary-create-button" mat-flat-button
              color="accent" (click)="startEnvironment(environment.name)">Start environment</button>
          </div>
        </div>
        <div class="connection-details">
          <div class="connection-details-title">Connection details for Vantage Database</div>
          <table class="connection-details-table">
            <colgroup>
              <col span="1" style="width: 15%;">
              <col span="1" style="width: 85%;">
            </colgroup>
            <tbody>
              <tr>
                <td>Host</td>
                <td class="table-row-value">
                  <div class="display-flex">
                    <div class="hide-overflow">{{environment.dnsName}}</div>
                    <div>
                      <fa-icon [icon]="faCopy" [matTooltipDisabled]="true" matTooltip="Copied to clipboard!"
                        #dnsTooltip="matTooltip" (click)="copyText(environment.dnsName, 'dns')"
                        class="copy-icon"></fa-icon>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Username</td>
                <td class="table-row-value">demo_user<fa-icon [icon]="faCopy" [matTooltipDisabled]="true"
                    matTooltip="Copied to clipboard!" #userTooltip="matTooltip" (click)="copyText('demo_user', 'user')"
                    class="copy-icon"></fa-icon></td>
              </tr>
              <tr>
                <td class="last">Password</td>
                <td class="table-row-value last password">Use the password you entered while creating the environment
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="connection-details-cta">Want to connect your favorite applications with your Vantage database? <a
            class="cta-link" href="https://quickstarts.teradata.com/" target="_blank">Learn more in Getting Started
            <fa-icon [icon]="faExternalLink"></fa-icon></a></div>
        <!-- <div class="bottom-section">
          <div class="help row">
            <div><img width="24" height="24" class="help-icon" src="./assets/icon-help.svg" /></div>
            <div class="help-text">Need help? Have comments or feedback?</div>
            <span class="toolbar-spacer"></span>
            <div><button mat-flat-button color="accent" (click)="sendEmail(environment.name)">Request help</button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
<div class="user-level-notifications">
  <div class="notifications" *ngFor="let notification of notifications | async">
    <div [ngClass]="['notification', 'notification-type-' + notification.type.toLowerCase()]"
      *ngIf="!notification.environment">
      <div class="notification-icon">
        <mat-icon>{{notification.type.toLowerCase()}}</mat-icon>
      </div>
      <div class="notification-body">
        <div class="middle-content">
          <div [ngClass]="['notification-title', 'notification-title-type-' + notification.type]">{{notification.title}}
          </div>
          <div [ngClass]="['notification-text', 'notification-text-type-' + notification.type]">{{notification.text}}
          </div>
        </div>
      </div>
      <span class="toolbar-spacer"></span>
      <div class="notification-delete">
        <img src="./assets/icon-close.svg" (click)="deleteNotification(notification)" />
      </div>
    </div>
  </div>
</div>
