<div class="forgot-password-container row layout">
  <div class="centered-content middle-width">
    <div class="action-in-progress">
      <div *ngIf="isLoadingResults">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>


    <div class="reset-password-container" *ngIf="!hasFinishedResetRequest">

      <h3 class="title">Reset your password</h3>

      <p>
        Enter the email associated with your account to change your password.
      </p>

      <div class="column-flow">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput [formControl]="email" required (keydown)="onKeyDown($event)" />
          <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>

        <div class="alternate-action">

          <button mat-stroked-button color="basic" (click)='onCancelClick()'>Cancel</button>


          <button mat-flat-button type="submit" color="accent" (click)="forgotPassword()"
            [disabled]="!email.valid">Reset</button>
        </div>


      </div>

    </div>

    <div class="has-submitted-reset-request" *ngIf="hasFinishedResetRequest">
      <app-icon-header-description-card header='Password reset requested'
        description="Check your email for instructions to reset your password">
      </app-icon-header-description-card>

 
        <button mat-button type="submit" [routerLink]="'/sign-in'">Log In</button>
  
    </div>

  </div>
</div>
