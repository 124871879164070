<c-carousel [dark]="true" [wrap]="false" class="w-100 cs-carousel-wrapper" [interval]="10000000">
    <div class="container">    
        <c-carousel-inner>
            <c-carousel-item>
                <div class="cs-content slide-1">
                    <div class="title">Test drive the world’s most powerful analytics engine for AI innovation</div>
                    <div class="text">Get a free hands-on demo environment to explore the most powerful engine for deploying end-to-end AI/ML pipelines: <strong>Teradata VantageCloud’s ClearScape Analytics™</strong>. See firsthand how you can unlock insights, accelerate time-to-value, and cut deployment time from months to days.</div>
                    <div class="text">Inside ClearScape Analytics Experience, you can:</div>
                    <div>
                        <ul>
                            <li>Spin up a live, self-service environment in minutes, with full access to all ClearScape Analytics functions and 30 GB of storage.</li>
                            <li>Explore 80+ AI/ML use cases across multiple industries and analytic functions through Jupyter notebooks containing sample data, live code, and visualizations.</li>
                            <li>Test tools and compatibility, develop solutions, explore capabilities with your own sample data and more.</li>
                        </ul>
                    </div>
                    <div class="img-container">
                        <img src="../../../assets/test-drive-powerful-ai-engine.svg" class="welcome-image-large" alt="" loading="lazy">
                        <img src="../../../assets/CSAE-intro_img-mobile@2x.svg" class="welcome-image-small" alt="" loading="lazy">
                    </div>
                </div>
            </c-carousel-item>
            <c-carousel-item>
                <div class="cs-content slide-2">
                    <div class="title">Included in your ClearScape Analytics&trade; Experience</div>
                    <div class="slide-2--box">                        
                        <div class="items container">
                            <div class="row">
                                <div class="item item-1 col-lg-2 d-none d-lg-block">
                                    <img src="../../../assets/icon-free-demos.svg" alt="">
                                </div>                                    
                                <div class="item item-2 col-12 col-lg-10">
                                    <div class="subtitle">Explore demos freely—you don’t need to be a programmer</div>
                                    <ul>
                                        <li>Each demo describes a business problem</li>
                                        <li>Demos pull test data from the cloud</li>
                                        <li>Step through preparation and execution of the analysis</li>
                                        <li>Gather insights from graphs and tables</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-2--box">                        
                        <div class="items container">
                            <div class="row">
                                <div class="item item-1 col-lg-2 d-none d-lg-block">
                                    <img src="../../../assets/icon-changes-real-time.svg" alt="">
                                </div>
                                <div class="item item-2 col-12 col-lg-10">
                                    <div class="subtitle">See your changes in real time</div>
                                    <ul>
                                        <li>Change key parameters and analyze the impact</li>
                                        <li>Take inspiration from demos to create your own</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-2--box">                        
                        <div class="items container">
                            <div class="row">
                                <div class="item item-1 col-lg-2 d-none d-lg-block">
                                    <img src="../../../assets/icon-change-demos.svg" alt="">
                                </div>
                                <div class="item item-2 col-12 col-lg-10">
                                    <div class="subtitle">Change the demos to suit your needs</div>
                                    <ul>
                                        <li>Change the demos to suit your needs If you make a mistake, simply transition your work to a second environment and then delete your original environment</li>
                                        <li>Use two environments to explore and understand the capabilities of ClearScape Analytic</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </c-carousel-item>

            <c-carousel-item>
                <div class="cs-content slide-3">
                    <div class="title">Demos available in to ClearScape Analytics&trade; Experience</div>
                    <div class="slide-3--box">                        
                        <div class="items container">
                            <div class="row">
                                <div class="item item-1 col-lg-2 d-none d-lg-block">
                                    <img src="../../../assets/multiple-industries.svg" alt="">
                                </div>                                    
                                <div class="item item-2 col-12 col-lg-10">
                                    <div class="subtitle">Multiple industries</div>
                                    <div class="row list-items-container">
                                        <div class="col-lg-6">
                                            <ul>
                                                <li>Automotive</li>
                                                <li>Energy & Natural Resources</li>
                                                <li>Financial</li>
                                                <li>Healthcare</li>
                                            </ul>
                                        </div>                              
                                        <div class="col-lg-6">
                                            <ul>
                                                <li>Manufacturing</li>
                                                <li>Retail</li>
                                                <li>Telco</li>
                                                <li>Travel & Transportation</li>
                                            </ul>
                                        </div>            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-3--box">                        
                        <div class="items container">
                            <div class="row">
                                <div class="item item-1 col-lg-2 d-none d-lg-block">
                                    <img src="../../../assets/complex-analytic-functions.svg" alt="">
                                </div>
                                <div class="item item-2 col-12 col-lg-10">
                                    <div class="subtitle">See your changes in real time</div>
                                    <div class="row list-items-container">
                                        <div class="col-lg-6">
                                            <ul>
                                                <li>Data Preparation</li>
                                                <li>Descriptive Statistics</li>
                                                <li>Feature Engineering</li>
                                                <li>Generative AI</li>
                                                <li>Geospatial</li>
                                                <li>Hypothesis testing</li>
                                                <li>Machine learning</li>
                                            </ul>          
                                        </div>
                                        <div class="col-lg-6"> 
                                            <ul>
                                                <li>Model Ops</li>
                                                <li>Object Storage</li>
                                                <li>Open-and-connected analytics</li>
                                                <li>Path Analytics</li>
                                                <li>Text Analysis</li>
                                                <li>Time series analysis</li>
                                            </ul>   
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slide-3--box">                        
                        <div class="items container">
                            <div class="row">
                                <div class="item item-1 col-lg-2 d-none d-lg-block">
                                    <img src="../../../assets/community-support.svg"alt="">
                                </div>
                                <div class="item item-2 col-12 col-lg-10">
                                    <div class="subtitle">Community support</div>
                                    <div class="row list-items-container">
                                        <div class="col-lg-6">
                                            <ul>
                                                <li>Getting Started</li>
                                            </ul>
                                        </div>                          
                                        <div class="col-lg-6">
                                            <ul> 
                                                <li>3rd Party Tools</li>                                    
                                            </ul>
                                        </div>                           
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </c-carousel-item>
            <c-carousel-item>
                <div class="cs-content slide-4">
                    <div class="title">Demo outputs from ClearScape Analytics&trade; Experience</div>
                    <div class="slide-4--box container">
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/4d-analytics-on-bike-sharing.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">4D Analytics on Bike Sharing</div>
                                <div class="description">Geospatial analysis of points, distances, areas and overlapping shapes.</div>
                            </div>
                        </div>
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/financial-customer-journey.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">Financial Customer Journey</div>
                                <div class="description text">nPath&reg; analysis of the steps to sales conversion and identitfication
                                    of events causing lost sales.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </c-carousel-item>
            <c-carousel-item>
                <div class="cs-content slide-5">
                    <div class="title">Demo outputs from ClearScape Analytics&trade; Experience</div>
                    <div class="slide-5--box container">
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/fourier-transformations.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">Fourier Transformations</div>
                                <div class="description text">One-dimensional and two-dimensional ananlysis of signal processing
                                    using Fourier Transforms.
                                </div>
                            </div>
                        </div>
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/retail-demand-forecasting.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">Retail Demand Forecasting</div>
                                <div class="description text">Using machine learning to forecase sales.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </c-carousel-item>
            <c-carousel-item>
                <div class="cs-content slide-6">
                    <div class="title">Demo outputs from ClearScape Analytics&trade; Experience</div>
                    <div class="slide-6--box container">
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/predictive-maintenance.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">Predictive Maintenance</div>
                                <div class="description text">Multi-variable correlation in preparation for machine learning to predict equipment failures.
                                </div>
                            </div>
                        </div>
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/text-term-frequency.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">Text Term Frequency</div>
                                <div class="description text">N-Gram analysis of product feedback comments
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </c-carousel-item>
            <c-carousel-item>
                <div class="cs-content slide-7">
                    <div class="title">Demo outputs from ClearScape Analytics&trade; Experience</div>
                    <div class="slide-7--box container">
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/heart-failure-prediction.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">Heart Failure Prediction</div>
                                <div class="description text">Predicting heart failure deaths based on physical characteristics and behaviors.</div>
                            </div>
                        </div>
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/anomaly-detection.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">Anomaly Detection</div>
                                <div class="description text">Detection of spot weld quality by determining relevant measurement features and training
                                    a model to detect anaomalies.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </c-carousel-item>
            <c-carousel-item>
                <div class="cs-content slide-8">
                    <div class="title">Demo outputs from ClearScape Analytics&trade; Experience</div>
                    <div class="slide-8--box container">
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/cash-demand-forecasting.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">Cash Demand Forecasting</div>
                                <div class="description">Cash demand forecasting for ATM using Autoregressive integrated moving average (ARIMA)</div>
                            </div>
                        </div>
                        <div class="item row">
                            <div class="col-lg-6"><img src="../../../assets/graph-analysis-of-cdr-records.svg" alt=""></div>
                            <div class="col-lg-6">
                                <div class="subtitle">Graph Analysis of CDR Records</div>
                                <div class="description text">Uses graph analysis to identify communities and key 
                                    influencers within Call Data Records. This uses Script 
                                    Table Operator to invoke external procedures to work 
                                    inside the Vantage database without exporting data.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </c-carousel-item>
            <c-carousel-item>
                <div class="cs-content slide-9">
                    <div class="title">Access a fully functional Vantage Database Management System</div>
                    <div class="subtitle">Use your ClearScape Analytics environment to:</div>
                    <ul> 
                        <li>Experience ClearScape Analytics functions applied to your data</li>
                        <li>Test your tools and applications using ClearScape Analytics</li>
                        <li>Access the Data Dictionary notebook to get copies of demo data</li>
                        <li>Test your current Database Management System query functionality vs Vantage</li>
                        <li>Develop new tools and solutions for your company to sell</li>
                        <li>Train students in advanced analytics</li>
                    </ul>
                    <div class="img-container">
                        <img src="../../../assets/Diagram_Desktop.svg" class="db-mngmt-system-large" alt="" loading="lazy">
                        <img src="../../../assets/Diagram_Mobile_30.svg" class="db-mngmt-system-small" alt="" loading="lazy">
                    </div>
                </div>
            </c-carousel-item>
        </c-carousel-inner>
        </div>
        <div class="indicators">
            <c-carousel-indicators></c-carousel-indicators>
        </div>
        
        <c-carousel-control [routerLink] caption="Previous" direction="prev" color="success"></c-carousel-control>
        <c-carousel-control [routerLink] caption="Next" direction="next"></c-carousel-control>
</c-carousel>