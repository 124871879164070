import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../shared/services/auth.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClearscapeService } from '../../clearscape.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { first } from 'rxjs';
import { IEnvironment } from 'src/app/shared/services/environment.types';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit {
  @ViewChild('tooltip') tooltip!: MatTooltip;
  mobileNavBarVisible: boolean = false;
  mobileNavBarCommunityMenuVisible: boolean = false;
  mobileNavBarDocsMenuVisible: boolean = false;
  faCopy = faCopy;

  constructor( private dialog: MatDialog,public router: Router, public authService: AuthService, private clipboardService: ClipboardService, private gtmService: GoogleTagManagerService,private clearscapeService:ClearscapeService, private firestore:AngularFirestore) { }
  ngOnInit() {}

  private openDialog(envs: IEnvironment[]) {
    const dialogRef = this.dialog.open(SignOutDialogComponent, {
      data: envs,
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(canSignOut => {
      if(canSignOut){
        this.authService.signOut()
      }
    });
  }

  showSignOutDialog() {
    this.mobileNavBarVisible = false;
    this.firestore.collection<IEnvironment>(`environments`, ref => ref.where('owner', '==', this.authService.userData.email)).valueChanges()
    .pipe(first())
    .subscribe((environments)=>{
      console.log('environments',environments)
      const envs = environments.filter((env)=>env.state === 'RUNNING');
      console.log('envs',envs);
      if(envs.length > 0){
        this.openDialog(envs);
      }else{
        this.authService.signOut();
      }
    })
    
  }

  toggleMobileNavBar() {
    this.mobileNavBarVisible = !this.mobileNavBarVisible;
  }

  copyToken() {
    this.clipboardService.copyToClipboard(localStorage.getItem("token")!, this.tooltip);
  }

  getAPIDocsLink() {
    return (environment.name === 'production') ? 'https://api.clearscape.teradata.com/api-docs' : `https://api.${environment.name}.clearscape.teradata.com/api-docs`;
  }

  toCreateAccountForm() {
    this.gtmService.pushTag({
      event: 'web_to_create_account'
    })
    this.mobileNavBarVisible = false;
    window.location.href = 'https://www.teradata.com/experience';
  }
}

@Component({
  selector: 'sign-out-dialog',
  templateUrl: 'sign-out.dialog.html',
  styleUrls: ['./sign-out.dialog.css']
})
export class SignOutDialogComponent {
  dontShowAgain: boolean = false;
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SignOutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEnvironment[],
    private clearscapeService:ClearscapeService,
    private authService: AuthService
  ) { }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
  
  onSignOut(): void {
    this.dialogRef.close(true);
  }

  onStopAndSignOut(): void {
    this.loading = true;
    this.clearscapeService.stopAll(this.data).subscribe({
      next: (value) => {
        console.log('Environment stopped successfully', value)
      },
      error: (error) => {
        console.log('Error stopping environment', error)
        this.loading = false;
      },
      complete: () => {
        console.log('All environments stopped successfully')
        this.authService.signOut();
        this.loading=false;
        this.dialogRef.close(true);
      }
    })
    
  }
}