import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../../shared/css/auth.css', './forgot-password.component.scss'],
})

export class ForgotPasswordComponent implements OnInit {
  constructor(public authService: AuthService, private matSnackBar: MatSnackBar, private router: Router) {}
  isLoadingResults: boolean = false;

  hasFinishedResetRequest: boolean = false;

  ngOnInit() {}

  email = new FormControl('', [Validators.required, Validators.email]);

  forgotPassword() {
    this.isLoadingResults = true;
    this.authService.forgotPassword(this.email.value!)
    .then(() => {
      this.isLoadingResults = false;
      this.hasFinishedResetRequest = true;
    })
    .catch((e) => {
      if (e.message.includes("auth/user-not-found")) {
        this.matSnackBar.open("User with this email address doesn't exist. Create an account first.", "OK");
      } else {
        this.matSnackBar.open(e.message, "OK");
      }
      this.isLoadingResults = false;
    });
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.email.hasError('email')) {
      return 'You must enter a valid email address';
    }
    return '';
  }

  isFormValid() {
    return !this.email.invalid;
  }

  onKeyDown(event: any) {
    if (event.keyCode === 13 && this.isFormValid()) {
      this.forgotPassword();
    }
  }

  onCancelClick() {
    this.router.navigate(['/sign-in']);
  }
}
