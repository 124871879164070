// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'ci',
  firebase: {
    apiKey: "AIzaSyAem8Iz7haLsVMLQN2AgbA7BjY4ARdXhUE",
    authDomain: "clearscape-ci.firebaseapp.com",
    projectId: "clearscape-ci",
    storageBucket: "clearscape-ci.appspot.com",
    messagingSenderId: "561066095864",
    appId: "1:561066095864:web:c79de041d9d4ce723db70d"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
