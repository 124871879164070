import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { Title } from '@angular/platform-browser';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { combineLatest, map, Observable, Subscription } from 'rxjs';
import { ClipboardService } from 'src/app/shared/services/clipboard.service';
import { DateService } from 'src/app/shared/services/date.service';
import { environment } from 'src/environments/environment';
import { ClearscapeService } from '../../clearscape.service';
import { AuthService } from '../../shared/services/auth.service';
import { containsOnlyAllowedCharacters, doesntEndWithHyphen, startsWithLetter } from './validators';
import { IEnvironment } from 'src/app/shared/services/environment.types';
import { ProfileType } from '../../shared/services/profile-type';
import { ProfileTypeService } from '../../shared/services/profile-type.service';
import { User } from 'src/app/shared/services/user';
import { Timestamp } from '@firebase/firestore-types';
const sleepBeforeRefresh = 8000;

export interface CreateEnvironmentData {
  environmentName: string;
  environmentPassword: string;
  region: string
}
export interface Notification {
  id: string;
  title: string;
  text: string;
  type: string;
  environment: string;
  suggestedAction: string;
}

export class Consumption {
  currentConsumption:number;
  maxCredits: number;
  resetDate: Date;
  constructor(currentConsumption: number, maxCredits: number, resetDate: Timestamp) {
    this.currentConsumption = currentConsumption;
    this.maxCredits = maxCredits;
    this.resetDate = resetDate.toDate();
    console.log(`resetAt: ${this.resetDate}`)
  }

  get percentage(): number {
    return this.currentConsumption / this.maxCredits * 100;
  }
}

const NOTIFICATION_TYPE_ICONS: Map<string,string> = new Map<string,string>([
  ['INFO', 'info'],
  ['WARNING', 'warning'],
  ['ALERT', 'info'],
  ['DANGER', 'cancel']
]);
  
export interface Utilization {
  currentUtilization: number;
  billingRestartDate: Date;
  budget: number;
}

export interface UtilizationUser extends User {
  utilization: Utilization
  currentConsumption: number;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DashboardComponent {
  @ViewChild('dnsTooltip') dnsTooltip!: MatTooltip;
  @ViewChild('userTooltip') userTooltip!: MatTooltip;
  title = 'ClearScape Analytics Experience Web Console';
  notifications: Observable<Notification[]>;
  notificationsSubscription: Subscription;
  user: Observable<User| undefined>
  consumption: Observable<Consumption|undefined>;
  profileType: Observable<ProfileType | undefined>;
  focused: boolean = true;


  displayedColumns = ['name', 'region', 'state', 'host', 'actions', 'expand'];
  columnsToDisplayWithExpand = [...this.displayedColumns, 'expand'];

  selectedEnvironment: IEnvironment | undefined;
  environments: Observable<IEnvironment[]>;
  environmentsSubscription: Subscription;
  notificationTypeIcons = NOTIFICATION_TYPE_ICONS;

  isLoadingResults: boolean = false;
  token: string = '';
  faCopy = faCopy;
  faExternalLink = faExternalLink;
  createEnvironmentData: CreateEnvironmentData = {
    environmentName: '',
    environmentPassword: '',
    region: 'us-central'
  };

  constructor(
    private dialog: MatDialog,
    private titleService: Title,
    private clearscapeService: ClearscapeService,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    public firestore: AngularFirestore,
    public dateService: DateService,
    private clipboardService: ClipboardService,
    private gtmService: GoogleTagManagerService,
    private profileTypeService: ProfileTypeService
  ) {
    
    this.notifications = firestore.collection<Notification>(`users/${authService.userData.email}/notifications`).valueChanges({ idField: 'id' });
    this.environments = firestore.collection<IEnvironment>(`environments`, ref => ref.where('owner', '==', authService.userData.email)).valueChanges();
    this.user = firestore.doc<User>(`users/${authService.userData.email}`).valueChanges();
    this.profileType = this.profileTypeService.getProfileTypes(this.user);

    this.consumption = combineLatest([this.user, this.profileType]).pipe(map(([user, profileType]) => {
      if (user && profileType) {
        return new Consumption(user.currentConsumption, profileType.maxCredits, user.resetDate);
      }
      return undefined;
    }))
    this.environmentsSubscription = this.environments.subscribe((envs: IEnvironment[]) => {
      console.log(`env list has changed`);

      if (!this.selectedEnvironment) {
        if (envs.length) {
          this.selectedEnvironment = envs[0];
        }
      } else {
        if (!envs.find(e => e.name === this.selectedEnvironment?.name)){
          if (envs.length > 0) {
            this.selectedEnvironment = envs[0];
          } else {
            this.selectedEnvironment = undefined;
          }
        } else {
          this.selectedEnvironment = envs.find(e => e.name === this.selectedEnvironment?.name);
        }
      }
    });
    
    this.notificationsSubscription = this.notifications.subscribe(
      (notifications: Notification[]) => {
        const hasNotification = notifications.length > 0;
        if (hasNotification && !this.focused) {
          this.onHandleNotificationReceived(notifications.length);
        }
      }
    )
  }

  ngOnInit() {

    this.titleService.setTitle(this.title);
    const token = localStorage.getItem('token');
    if (token) {
      this.setToken(token);
    }

  }

  ngOnDestroy() {
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
  }



  @HostListener('window:focus', ['$event'])
  onFocus() {
    this.focused = true;
    this.titleService.setTitle('Teradata ClearScape Analytics Experience Web');
  }

  @HostListener('window:blur', ['$event'])
  onBlur() {
    this.focused = false;
  }

  onHandleNotificationReceived(amount: Number) {
    this.titleService.setTitle(`(${amount}) Teradata ClearScape Analytics Experience Web`);
  }

  openRestApiDocs(): void {
    window.open((environment.name === 'production') ? 'https://api.clearscape.teradata.com/api-docs' : `https://api.${environment.name}.clearscape.teradata.com/api-docs`, "_blank");
  }

  copyText(text: string, name: string) {
    if (name === 'dns') {
      this.clipboardService.copyToClipboard(text, this.dnsTooltip);
    }

    if (name === 'user') {
      this.clipboardService.copyToClipboard(text, this.userTooltip);
    }
  }

  copyToken() {
    this.clipboardService.copyToClipboard(localStorage.getItem("token")!, this.userTooltip);
  }

  stopEnvironment(environmentName: string) {
    this.clearscapeService.stop(this.token, environmentName).subscribe({
      next: (response: any) => {
        this.handleResponse(response);
      },
      error: (e) => {
        this.handleError(e);
      }
    });
    this.isLoadingResults = true;
    this.sleep(sleepBeforeRefresh).then(() => {
      this.isLoadingResults = false;
    });
  }

  startEnvironment(environmentName: string) {
    this.clearscapeService.start(this.token, environmentName).subscribe(
      {
        next: (response: any) => {
          this.handleResponse(response);
        },
        error: (e) => {
          this.handleError(e);
        }
      });
    this.isLoadingResults = true;
    this.sleep(sleepBeforeRefresh).then(() => {
      this.isLoadingResults = false;
    });
  }

  deleteEnvironment(environmentName: string) {
    this.clearscapeService.delete(this.token, environmentName).subscribe({
      next: (response: any) => {
        this.handleResponse(response);
      },
      error: (e) => {
        this.handleError(e);
      }
    });
    this.isLoadingResults = true;
    this.sleep(sleepBeforeRefresh).then(() => {
      this.isLoadingResults = false;
    });
  }

  createEnvironment(createEnvironmentData: CreateEnvironmentData) {
    this.clearscapeService.create(this.token, createEnvironmentData.environmentName,
      createEnvironmentData.environmentPassword, createEnvironmentData.region).subscribe({
        next: (response: any) => {
          this.gtmService.pushTag({
            event: 'web_create_env_success'
          })
          this.handleResponse(response);
        },
        error: (e) => {
          this.handleError(e);
        }
      });
    this.isLoadingResults = true;
    this.sleep(sleepBeforeRefresh).then(() => {
      this.isLoadingResults = false;
    });
  }

  setToken(token: string) {
    this.token = token;
  }

  createEnvironmentSubmitted(createEnvironmentData: CreateEnvironmentData) {
    this.createEnvironment(createEnvironmentData);
  }

  sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  
  handleError(e: any) {
    console.log('handleError');
    console.log(e);
    this.isLoadingResults = false;
    this.snackBar.open((e.error && e.error.message) ? e.error.message : e.error, 'OK');
  }

  handleResponse(response: any) {
    console.log('handleResponse');
    console.log(JSON.stringify(response, null, 2));
  }

  showCreateEnvironmentDialog() {
    this.gtmService.pushTag({
      event: 'web_create_env_dialog_show'
    })
    const dialogRef = this.dialog.open(CreateEnvironmentDialogComponent, {
      data: this.createEnvironmentData,
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed with result: ' + JSON.stringify(result));
      if (result) {
        this.createEnvironmentData.environmentName = result.environmentName;
        this.createEnvironmentData.environmentPassword = result.environmentPassword;
        this.createEnvironmentData.region = result.region;
        this.createEnvironment(this.createEnvironmentData);
        this.gtmService.pushTag({
          event: 'web_create_env_init'
        })
      }
    });
  }

  goToDemos() {
    this.gtmService.pushTag({
      event: 'web_launch_demos_success'
    })
    const url = (this.selectedEnvironment!.services.find(e => e.name === "Jupyterlab"))?.url;
    window.open(url, "_blank");
  }

  sendEmail(machineName: string) {
    const mailText = 'mailto:abc@abc.com+?subject=files&body=';
    window.location.href = 'mailto:SC230208@teradata.com?subject=ClearScape%20Analytics%20Experience%20Question%20' + machineName;
  }

  deleteNotification(notification: Notification) {
    console.log(notification);
    this.firestore.doc(`users/${this.authService.userData.email}/notifications/${notification.id}`).delete();
  }

  startEnvironmentAndDismiss(environment:IEnvironment,notification: Notification) {
    this.deleteNotification(notification);
    this.startEnvironment(environment.name); 
  }

  createEnvironmentAndDismiss(notification: Notification) {
    this.deleteNotification(notification);
    this.showCreateEnvironmentDialog();
  }

  requestHelpAndDismiss(notification: Notification) {
    this.deleteNotification(notification);
    this.sendEmail('general')
  }

}

@Component({
  selector: 'create-environment-dialog',
  templateUrl: 'create-environment.dialog.html',
  styleUrls: ['./create-environment.dialog.css']
})
export class CreateEnvironmentDialogComponent {
  hide: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<CreateEnvironmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateEnvironmentData,
    private gtmService: GoogleTagManagerService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.gtmService.pushTag({
      event: 'web_create_env_attempt'
    })
    console.log(`onSubmit()`);
    if (this.isFormValid()) {
      console.log('form valid, closing to submit');
      this.dialogRef.close({
        environmentName: this.environmentName.value,
        environmentPassword: this.environmentPassword.value,
        region: this.region.value
      });
    }
  }

  onKeyDown(event: any) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  isFormValid(): boolean {
    return !(this.environmentName.value === '' || this.region.invalid || this.environmentName.invalid || this.environmentPassword.invalid);
  }

  environmentName = new FormControl('', [Validators.required, startsWithLetter, containsOnlyAllowedCharacters, doesntEndWithHyphen, Validators.maxLength(40), Validators.pattern('^[a-z]([-a-z0-9]*[a-z0-9])?$')]);
  environmentPassword = new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z].*$'), Validators.minLength(8)]);
  region = new FormControl('us-central', [Validators.required]);

  getErrorMessageForEnvironmentName() {
    if (this.environmentName.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.environmentName.hasError('startsWithLetter')) {
      return 'Environment name must start with a lowercase letter';
    }
    if (this.environmentName.hasError('containsOnlyAllowedCharacters')) {
      return 'Environment name may contain only lowercase letters, digits and hyphens';
    }
    if (this.environmentName.hasError('doesntEndWithHyphen')) {
      return 'Environment name must not end with a hyphen';
    }
    if (this.environmentName.hasError('pattern')) {
      return 'Environment name must match pattern: [a-z]([-a-z0-9]*[a-z0-9])?';
    }
    if (this.environmentName.hasError('maxlength')) {
      return 'Environment name must have 40 characters or less.';
    }
    return '';
  }

  getErrorMessageForPassword() {
    if (this.environmentPassword.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.environmentPassword.hasError('pattern')) {
      return 'Environment password must start with a letter';
    }
    if (this.environmentPassword.hasError('minlength')) {
      return 'Environment password must have minimum 8 characters';
    }
    return '';
  }

  getErrorMessageForRegion() {
    if (this.region.hasError('required')) {
      return 'You must enter a value';
    }
    return '';
  }
}

