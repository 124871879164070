<h1 mat-dialog-title class="header">
  <div class="title">Create environment</div>
  <img (click)="onNoClick()" src="./assets/icon-close.svg" />
</h1>
<div mat-dialog-content>
  <input autocomplete="false" type="search" style=" overflow: hidden; display: none " />
  <mat-form-field appearance="outline">
    <mat-label>Environment name</mat-label>
    <input id="environment-name" matInput [formControl]="environmentName" required (keydown)="onKeyDown($event)" autocomplete="off">
    <mat-error *ngIf="environmentName.invalid">{{getErrorMessageForEnvironmentName()}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Create database password</mat-label>
    <input id="password" [type]="hide ? 'password' : 'text'" matInput [formControl]="environmentPassword" required (keydown)="onKeyDown($event)" autocomplete="new-password">
    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-hint>Create a unique password to sign in as 'demo_user' and 'dbc' database users.</mat-hint>
    <mat-error *ngIf="environmentPassword.invalid">{{getErrorMessageForPassword()}}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Region</mat-label>
    <mat-select id="region" [formControl]="region">
      <mat-option value="us-central">us-central</mat-option>
      <mat-option value="us-east">us-east</mat-option>
      <mat-option value="us-west">us-west</mat-option>
      <mat-option value="southamerica-east">southamerica-east</mat-option>
      <mat-option value="europe-west">europe-west</mat-option>
      <mat-option value="asia-south">asia-south</mat-option>
      <mat-option value="asia-northeast">asia-northeast</mat-option>
      <mat-option value="asia-southeast">asia-southeast</mat-option>
      <mat-option value="australia-southeast">australia-southeast</mat-option>
    </mat-select>
    <mat-error *ngIf="region.invalid">{{getErrorMessageForRegion()}}</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button mat-dialog-cancel (click)="onNoClick()">Cancel</button>
  <button mat-flat-button [disabled]="!isFormValid()"
    (click)="onSubmit()" color="primary" type="submit">Create</button>
</div>
