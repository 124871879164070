
<div class="row-x">
  <div class="content column-x slides-container" [class.from-validation]="from == 'validation'">
    <app-slides class="app-slides"></app-slides>
  </div>
  <div class="content column-x login-container">
    <div class="centered-content">
      <div class="action-in-progress">
        <div *ngIf="isLoadingResults">
          <mat-progress-bar class="progress-bar" mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
      <h1 class="form-name"><p class="bold">ClearScape Analytics&trade;</p> <span>Experience</span></h1>
      <h3 class="title">Log in</h3>
      <div class="column-flow">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input id="email" matInput [formControl]="email" required (keydown)="onKeyDown($event)" />
          <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input id="password" matInput [type]="hide ? 'password' : 'text'" [formControl]="password" required (keydown)="onKeyDown($event)" />
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="password.invalid">{{getErrorMessage()}}</mat-error>
        </mat-form-field>

        <div class="alternate-action">
          <button mat-flat-button type="submit" color="accent" class="login-btn"
        (click)="signIn()" [disabled]="!isFormValid()">Log in</button>
        </div>

        <div class="action-buttons row ">
          <div class="left-align column"><a [routerLink]="'/forgot-password'">Trouble logging in?</a></div>
        </div>
      </div>
    </div>
  </div>
</div>
