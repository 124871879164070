<div class="row layout">
  <app-auth-message class="registration-reasons"></app-auth-message>
  <div class="column content">
    <div class="centered-content extended-width">
    <div class="action-in-progress">
      <div *ngIf="isLoadingResults">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
    <h3 class="title">Thank you for registering</h3>
    <div>
      <div class="formGroup" *ngIf="authService.userData as user">
        <p class="text-center">
          We have sent a confirmation email to {{ user.email }}.
        </p>
        <p class="text-center">
          Please check your email and click on the link to verify your email
          address.
        </p>
      </div>

      <div class="action-buttons">
        <button mat-flat-button type="submit" color="accent"
        (click)="sendVerificationMail()">Resend Verification Email</button>
      </div>
    </div>
  </div>
</div>
