import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['sign-in.component.scss', '../../shared/css/auth.css'],
})

export class SignInComponent implements OnInit {
  constructor(  public authService: AuthService,
                private matSnackBar: MatSnackBar,
                private router: Router,
                private route: ActivatedRoute,
                private gtmService: GoogleTagManagerService) {}
  isLoadingResults: boolean = false;
  from: string = '';

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required]);
  hide = true;

  ngOnInit() {
    this.route.queryParamMap
      .subscribe((params) => {
        this.from = params.get('from')!;
      }
    );
  }

  async signIn() {
    this.gtmService.pushTag({
      event: 'web_signin_attempt'
    })
    this.isLoadingResults = true;
    try {
      await this.authService.signIn(this.email.value!, this.password.value!);
      this.gtmService.pushTag({
        event: 'web_signin_success'
      })
    } catch (e: any) {
      console.log(e);
      if (e.message.includes("auth/user-not-found")) {
        this.matSnackBar.open("User doesn't exist. Create an account first.", "OK");
      } else if (e.message.includes("auth/wrong-password")) {
        this.matSnackBar.open("Password incorrect. Enter the correct password.", "OK");
      } else if (e.message.includes("auth/email-not-verified")) {
        this.matSnackBar.open("Email not verified. Please verify your email.", "VERIFY EMAIL")
        .onAction()
        .subscribe(() => this.router.navigateByUrl('verify-email-address'));
      } else {
        this.matSnackBar.open(e.message, "OK");
      }
    };
    this.isLoadingResults = false;
  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.email.hasError('email')) {
      return 'You must enter a valid email address';
    }
    if (this.password.hasError('required')) {
      return 'You must enter a value';
    }
    return '';
  }

  isFormValid() {
    return !this.email.invalid && !this.password.invalid;
  }

  onKeyDown(event: any) {
    if (event.keyCode === 13 && this.isFormValid()) {
      this.signIn();
    }
  }

}
