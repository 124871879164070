import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { CreateEnvironmentDialogComponent } from './components/dashboard/dashboard.component';
import { SignOutDialogComponent } from './components/header/header.component'

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { CarouselModule } from '@coreui/angular';


import { environment } from '../environments/environment';
import { DaysRemainingPipe } from './days-remaining.pipe';
import { AuthGuard } from './shared/guard/auth.guard';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthActionComponent } from './components/auth-action/auth-action.component';
import { AuthMessageComponent } from './components/auth-message/auth-message.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { HeaderComponent } from './components/header/header.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AutoFocus } from './components/sign-up/autofocus.directive';
import { LicenseDialogComponent, SignUpComponent } from './components/sign-up/sign-up.component';
import { SlidesComponent } from './components/slides/slides.component';
import { UtilizationWidgetComponent } from './components/utilization-widget/utilization-widget.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

// routing
import { AppRoutingModule } from './app-routing.module';

// service
import { ResetPasswordComponent } from './components/auth-action/reset-password/reset-password.component';
import { AuthService } from './shared/services/auth.service';
import { ConfirmEmailVerificationComponent } from './components/auth-action/confirm-email-verification/confirm-email-verification.component';
import { IconHeaderDescriptionCardComponent } from './components/icon-header-description-card/icon-header-description-card.component';

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent, pathMatch: 'prefix' },
  { path: 'register-user', component: SignUpComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },
  { path: 'auth-action', component: AuthActionComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    DaysRemainingPipe,
    CreateEnvironmentDialogComponent,
    SignOutDialogComponent,
    SignInComponent,
    SignUpComponent,
    AutoFocus,
    LicenseDialogComponent,
    AuthMessageComponent,
    ForgotPasswordComponent,
    UtilizationWidgetComponent,
    VerifyEmailComponent,
    AuthActionComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    SlidesComponent,
    ResetPasswordComponent,
    ConfirmEmailVerificationComponent,
    IconHeaderDescriptionCardComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    FontAwesomeModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatTooltipModule,
    CarouselModule,
    RouterModule.forRoot(routes)
  ],
  providers: [
    Title,
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 20000}},
    {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    {provide: 'googleTagManagerId',  useValue: 'GTM-5XXHJRH'},
    AuthService,
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
