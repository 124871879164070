import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Tell dayjs to use the utc plugin
dayjs.extend(utc);

@Injectable({
  providedIn: 'root'
})
export class DateService {
  constructor() { }

  convertUTCToLocalInMessage(message: string): string | undefined {
    const utcTimeRegex: RegExp = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/;

    const match = message.match(utcTimeRegex);
    if (!match) {
      console.warn('No UTC timestamp found in message.');
      return message;
    }

    const localTime = this.convertUTCToLocal(match[1]);
    const convertedMessage = message.replace(utcTimeRegex, `${localTime}`);

    // convert (UTC) to (Local Time)
    const utcRegex = /\(UTC\)/;
    const convertedMessageWithLocalTime = convertedMessage.replace(utcRegex, '(Local Time)');

    return convertedMessageWithLocalTime;
  }

  convertUTCToLocal(utcTime: string): string {
    return dayjs.utc(utcTime).local().format('YYYY-MM-DD HH:mm:ss');
  }
}
