import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-auth-message',
  templateUrl: './auth-message.component.html',
  styleUrls: ['../../shared/css/auth.css', './auth-message.component.css'],
})

export class AuthMessageComponent {
  constructor(public router: Router, private gtmService: GoogleTagManagerService) {}

  signUpToday() {
    this.gtmService.pushTag({
      event: 'web_signup_today'
    })
    this.router.navigate(['/register-user']);
  }
}
