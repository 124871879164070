import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent implements OnInit {
  private window: any;

  constructor(public router: Router, @Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView!;
  }
  ngOnInit() {}

  showConsentForm() {
    this.window.ctrack.openConsentCenter();
  }
}
