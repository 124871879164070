import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthActionService {

  isLoadingResults: boolean = false;
  hasSucceeded: boolean = false;
  actionCode: string = "";
  mode: string = "";

  constructor(
    private router: Router
  ) { }
 
  
  hasMultilineError(field: FormControl) {
    
    const hasError = field.invalid && (field.dirty || field.touched);
  
    if (hasError) {
      const error = this.getErrorMessage(field);
      return error.length > 30;
    }
    return false;
  }

  getErrorMessage(field: FormControl) {
    if (field.hasError('required')) {
      return 'You must enter a value';
    }
    if (field.hasError('passwordComplexity')) {
      return field.getError('passwordComplexity');
    }

    if (field.hasError('email')) {
      return 'Please enter a valid email address';
    }

    return '';
  }

  goBack() {
    this.router.navigate(['/sign-in']);
  }

}
