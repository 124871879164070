import { Injectable } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root'
})

export class ClipboardService {
  constructor(public clipboard: Clipboard) { }

  async copyToClipboard(text: string, tooltip: MatTooltip) {
    try {
      await this.clipboard.copy(text);
      this.showTooltip(tooltip);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  showTooltip(tooltip: MatTooltip) {
    tooltip.disabled = false;
    tooltip.show();
    setTimeout(() => {
      tooltip.hide();
      tooltip.disabled = true;
    }, 2000);
  }
}
