<section class="navbar-menu-sections">
  <header>
    <nav class="navbar navbar-expand-sm bg-light">
      <div class="container-fluid">
        <div class="block block--downloads-branding">
          <a href="https://www.teradata.com/" class="navbar-brand align-items-center " aria-label="">
            <img src="/assets/td-logo.svg" width="30" alt="Teradata Downloads logo" class="me-2">
          </a>
          <span>ClearScape Analytics™ Experience</span>
        </div>
      </div>
      
 

      
      <button class="navbar-toggler collapsed" [class.collapsed]="!mobileNavBarVisible" type="button"
      data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar" aria-expanded="false"
      (click)="toggleMobileNavBar()" *ngIf="router.url.split('#')[0] === '/dashboard' || router.url.split('#')[0] === '/sign-in'" >
      <span class="navbar-toggler-icon"></span>
    </button>
    
    <div class="navbar-collapse collapse" [class.show]="mobileNavBarVisible" id="collapsibleNavbar" *ngIf="router.url.split('#')[0] === '/sign-in'">
      <div class="container-end">
        <div class="quick-links-container">
            <a class="quick-link" href="https://www.teradata.com/platform/clearscape-analytics"><span>Learn more</span><mat-icon>east</mat-icon></a>
            <a class="quick-link" href="https://developers.teradata.com"><span>Developer Portal</span><mat-icon>east</mat-icon></a>
        </div>
        <div class="btns-container">
        <a (click)="toCreateAccountForm()"
          class="nav-link">
          <div class="button-outline">
            Create account
          </div>
        </a>
        </div>
        <footer>
          Teradata.com
        </footer>
      </div>

    </div>

    <div class="navbar-collapse collapse" [class.show]="mobileNavBarVisible" id="collapsibleNavbar" *ngIf="router.url.split('#')[0] === '/dashboard'">
      <div class="container-end">

        <div class="btns-container">
          <a [matTooltipDisabled]="true" matTooltip="Copied to clipboard!" #tooltip="matTooltip" (click)="copyToken()"
          class="nav-link">
          <div class="button-outline">
            Copy API Token
            <img src='./assets/icon-copy.svg' width="15" alt="Copy API Token" class="icon">
          </div>
        </a>


        <a [href]="getAPIDocsLink()" rel="noopener noreferrer" target="_blank" class="nav-link">
          <div class="button-outline">
            Rest API Docs
            <img src='./assets/icon-export.svg' width="13" alt="Read API Docs" class="icon">
          </div>
        </a>

        <a (click)="showSignOutDialog()" class="nav-link">
          <div class="button-outline">
            Sign Out
            <img src='./assets/icon-arrow-right.svg' width="15" alt="Sign out user" class="icon">
          </div>
        </a>
        </div>
        <footer>
          Teradata.com
        </footer>
      </div>

    </div>
     


    </nav>
  </header>
</section>
