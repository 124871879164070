import { Component, OnInit, Input } from '@angular/core';

export interface Utilization {
  currentUtilization: number;
  billingRestartDate: Date;
  budget: number;
}

@Component({
  selector: 'app-utilization-widget',
  templateUrl: './utilization-widget.component.html',
  styleUrls: ['./utilization-widget.component.css'],
})
export class UtilizationWidgetComponent implements OnInit {
  @Input()
  utilization!: Utilization;

  constructor() {
  }

  ngOnInit() {}
}
