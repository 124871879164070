import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'daysRemaining'
})
export class DaysRemainingPipe implements PipeTransform {
  daysToLive: number = 60;
  millisecondsInDay: number = 1000 * 60 * 60 * 24;

  transform(createDate: Date): string {
    var diff = new Date(createDate).getTime() + (this.daysToLive * this.millisecondsInDay) - new Date().getTime();
    var diffDays = Math.ceil(diff / this.millisecondsInDay);
    if (diffDays < 0) {
      diffDays = 0;
    }
    return diffDays.toString();
  }

}
