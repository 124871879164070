import {AbstractControl, ValidatorFn} from '@angular/forms';

// ^[a-z]([-a-z0-9]*[a-z0-9])?$
export function startsWithLetter(control: AbstractControl) {
  if (!control.value.match('^[a-z]')) {
    return { startsWithLetter: true };
  } else {
    return null;
  }
}

export function containsOnlyAllowedCharacters(control: AbstractControl) {
  if (!control.value.match('^[-a-z0-9]*$')) {
    return { containsOnlyAllowedCharacters: true };
  } else {
    return null;
  }
}

export function doesntEndWithHyphen(control: AbstractControl) {
  if (control.value.match('-$')) {
    return { doesntEndWithHyphen: true };
  } else {
    return null;
  }
}
