<h3>Email Validation</h3>
<div class="column-flow" *ngIf="this.authActionService.hasSucceeded">
  <div class="formGroup">
    <p class="text-center">
      Your email has been successfully validated.
    </p>
  </div>
  <div class="action-buttons">
    <button mat-flat-button type="submit" color="accent" [routerLink]="'/sign-in'"
      [queryParams]="{from: 'validation'}">Continue to log in</button>
  </div>
</div>
