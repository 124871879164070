import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from './user';
import { ProfileType } from './profile-type';
import { first, Observable, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileTypeService {

  constructor(
    public firestore: AngularFirestore
  ) { 
    
  }

  getProfileTypes(userObservable:Observable<User|undefined>): Observable<ProfileType | undefined> {
    return userObservable
      .pipe(first())
      .pipe(switchMap(user => this.firestore.collection<ProfileType>('profileTypes').doc(user?.profileType?.toLowerCase() || 'free').valueChanges())) 
      .pipe(first());
  }
}
