<h3 *ngIf="!this.authActionService.hasSucceeded">Set your password</h3>
<div class="column-flow" *ngIf="!this.authActionService.hasSucceeded">
  <mat-form-field appearance="outline" [class.has-error]="this.authActionService.hasMultilineError(password)">
    <mat-label>Password</mat-label>
    <input matInput [formControl]="password" [type]="hidePassword ? 'password' : 'text'" required />

    <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
      <mat-icon class='btn-visibility'>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error *ngIf="password.invalid">{{this.authActionService.getErrorMessage(password)}}</mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline" [class.has-error]="this.authActionService.hasMultilineError(passwordConfirm)">
    <mat-label>Confirm Password</mat-label>
    <input matInput [formControl]="passwordConfirm" [type]="hidePassword ? 'password' : 'text'" required />

    <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
      <mat-icon class='btn-visibility'>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
    </button>
    <mat-error *ngIf="passwordConfirm.invalid">{{this.authActionService.getErrorMessage(passwordConfirm)}}</mat-error>
  </mat-form-field>

  <div class="action-buttons">
    <button mat-stroked-button color="basic" (click)="this.authActionService.goBack()">Cancel</button>
    <button mat-flat-button type="submit" color="accent" (click)="setPassword(password.value!)"
      [disabled]="!password.valid">Set Password</button>
  </div>
</div>
<div class="column-flow password-reset-requested-container" *ngIf="this.authActionService.hasSucceeded">

  <app-icon-header-description-card header='Your password has been set'
    description="You may continue to login"
    icon='confirm-icon.svg'
    >
  </app-icon-header-description-card>

  <div class="action-buttons">
    <button mat-button type="submit" [routerLink]="'/sign-in'">Log In</button>
  </div>
</div>

