import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { IEnvironment } from './shared/services/environment.types';
import { merge } from 'rxjs';

const clearScapeAPIUrl = (environment.name === 'production') ? 'https://api.clearscape.teradata.com/environments' : `https://api.${environment.name}.clearscape.teradata.com/environments`;

@Injectable({
  providedIn: 'root'
})
export class ClearscapeService {

  constructor(private http: HttpClient, private firestore:AngularFirestore) { }

  public list(token: string) {
    return this.http.get(`${clearScapeAPIUrl}`, {
      headers: {'Authorization': `Bearer ${token}`}
    });
  }
  
  public stopAll(environments: IEnvironment[]){
      return merge.apply(this,(environments.map((environment) => {
        return this.stop(localStorage.getItem('token')!, environment.name);//.pipe(catchError(()=>of(environment)));
      })))
  }

  public stop(token: string, environmentName: string) {
    return this.http.patch(`${clearScapeAPIUrl}/${environmentName}`, {
      operation: 'stop'
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
  }

  public start(token: string, environmentName: string) {
    return this.http.patch(`${clearScapeAPIUrl}/${environmentName}`, {
      operation: 'start'
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
  }

  public delete(token: string, environmentName: string) {
    return this.http.delete(`${clearScapeAPIUrl}/${environmentName}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
  }

  public create(token: string, environmentName: string, environmentPassword: string, region: string) {
    return this.http.post(`${clearScapeAPIUrl}`, {
      name: environmentName,
      password: environmentPassword,
      region
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });
  }




}
