import { Component, OnInit } from '@angular/core';
import { AuthActionService } from 'src/app/shared/services/auth-action.service';

@Component({
  selector: 'app-confirm-email-verification',
  templateUrl: './confirm-email-verification.component.html',
  styleUrls: ['./confirm-email-verification.component.scss']
})
export class ConfirmEmailVerificationComponent implements OnInit {

  constructor(
    public authActionService: AuthActionService
  ) { }

  ngOnInit(): void {
  }

}
