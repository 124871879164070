<h1 mat-dialog-title class="header">
  <div class="title">Sign out with environment(s) running?</div>
  <button tabindex="-1" mat-icon-button (click)="onNoClick()" >
    <mat-icon>close</mat-icon>
  </button>
</h1>
<div class="loading">
  <mat-progress-bar *ngIf="loading" color="accent" mode="indeterminate"></mat-progress-bar>
</div>
<div mat-dialog-content>
  <div>Are you sure you want to sign out with environment(s) running? You can save 2 credit hours by stopping your unused environments.</div>
</div>
<div mat-dialog-actions>
  <section class="dont-show-again-section" style="display: none;">
    <mat-checkbox color="primary" class="example-margin" [(ngModel)]="dontShowAgain" [disabled]="loading">Don’t show again</mat-checkbox>
  </section>
  <button mat-stroked-button mat-dialog-cancel (click)="onSignOut()">Sign out</button>
  <button mat-flat-button cdkFocusInitial (click)="onStopAndSignOut()" color="primary" type="submit" [disabled]="loading">Stop and sign out</button>
</div>
