import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as auth from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { firstValueFrom } from 'rxjs';

export interface Profile {
  firstname: string,
  lastname: string,
  company: string,
  emailMarketingConsent: boolean
  createdAt: Timestamp;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}

  // Sign in with email/password
  async signIn(email: string, password: string) {
    var result = await this.afAuth.signInWithEmailAndPassword(email, password);
    if (!result.user?.emailVerified) {
      throw new Error("auth/email-not-verified");
    }
    const token = await this.getUserToken(result.user?.email!);
    localStorage.setItem('user', JSON.stringify(result.user));
    localStorage.setItem('token', token);

    this.afAuth.authState.subscribe((user) => {
      console.log(`authState updated with: ${JSON.stringify(user)}`);
      if (!user) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
      }
    });

    await this.ngZone.run(async () => {
      await this.router.navigate(['dashboard']);
    });
  }

  // Sign up with email/password
  async signUp(email: string, password: string, profile: Profile) {
    // we wan't to preserve form information first
    // before the create user flow kicks off as it expects this info
    await this.setUserProfile(email, profile);
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        await this.sendVerificationMail();
      });
  }

  // Send email verification when new user sign up
  sendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(async () => {
        const user = await this.afAuth.currentUser;
        localStorage.setItem('user', JSON.stringify({
          email: user?.email
        }));
      })
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }

  // Reset forgotten password
  forgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail);
  }

  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  get userData(): any {
    return JSON.parse(localStorage.getItem('user')!);
  }

  // Sign in with Google
  googleAuth() {
    return this.authLogin(new auth.GoogleAuthProvider()).then((res: any) => {
      if (res) {
        this.router.navigate(['dashboard']);
      }
    });
  }

  // Auth logic to run auth providers
  authLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserProfile(email: string, profile: Profile) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `signups/${email.toLowerCase()}`
    );
    return userRef.set(profile, {
      merge: true,
    });
  }

  async getUserToken(email: string) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${email}`
    );
    var user: any;
    // if this is a new registration, it may take a while before a new user profile gets
    // created. We need to loop till the REST API token is available

    for (var i=1; i<100; i++) {
      console.log(`Attempt ${i}: reading token.`);
      try {
        user = await firstValueFrom(userRef.get());
        if(user.data() && user.data().token) {
          console.log(`Token retrieved.`);
          break;
        }
      } catch (e: any) {
        console.log(e);
      }
    }
    if (!user || !user.data()) {
      throw new Error('Unable to connect to ClearScape Analytics Experience backend. This usually means that your network configuration is not allowing connections to https://firestore.googleapis.com/. Please contact your network administrator to allow access to this url.');
    }
    return user!.data().token;
  }

  // Sign out
  signOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.router.navigate(['sign-in']);
    });
  }

  async handleVerifyEmail(actionCode: string)  {
    console.log(`actionCode: ${actionCode}`);
    await this.afAuth.applyActionCode(actionCode);
  }

  async handleResetPassword(actionCode: string, newPassword: string) {
    await this.afAuth.verifyPasswordResetCode(actionCode);
    await this.afAuth.confirmPasswordReset(actionCode, newPassword);
  }
}
