import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthActionService } from 'src/app/shared/services/auth-action.service';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-auth-action',
  templateUrl: './auth-action.component.html',
  styleUrls: ['./auth-action.component.scss', '../../shared/css/auth.css'],
})

export class AuthActionComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    public authActionService: AuthActionService
    ) { }

 

  async ngOnInit() {
    const urlParams = this.router.parseUrl(this.router.url).queryParamMap;
    const urlMode = urlParams.get("mode");
    this.authActionService.actionCode = urlParams.get("oobCode")!;
    const continueUrl = urlParams.get("continueUrl");
    const lang = urlParams.get("lang");
    switch (urlMode) {
      case "verifyEmail":
        this.authActionService.mode = urlMode;
        this.authActionService.isLoadingResults = true;
        try {
          this.gtmService.pushTag({
            event: 'web_validate_email_attempt'
          })
          await this.authService.handleVerifyEmail(this.authActionService.actionCode!);
          this.authActionService.hasSucceeded = true;
          this.gtmService.pushTag({
            event: 'web_validate_email_success'
          })
        } catch (e: any) {
          console.log(e);
          this.matSnackBar.open(e.message, "OK");
        }
        this.authActionService.isLoadingResults = false;
        break;
      case "recoverPassword":
      case "resetPassword":
        this.authActionService.mode = urlMode;
        break;

      default:
        this.matSnackBar.open("Invalid operation", "OK");
    }

  }


}
