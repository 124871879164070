import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthActionService } from 'src/app/shared/services/auth-action.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  password = new FormControl('', {
    validators: [Validators.required, this.passwordValidator()],
    updateOn: 'blur'
  });
  passwordConfirm = new FormControl('', {
    validators: [Validators.required, this.passwordValidator()],
    updateOn: 'blur'
  });

  // Password toggle
  hidePassword = true;
  hideConfirmPassword = true;

  constructor(
    public authService: AuthService,
    private matSnackBar: MatSnackBar,
    public authActionService: AuthActionService) { }

  ngOnInit(): void {
  }

  async setPassword(password: string) {
    try {

      if (password !== this.passwordConfirm.value) {
        this.matSnackBar.open("Passwords do not match", "OK");
        return;
      }
      this.authActionService.isLoadingResults = true;
      await this.authService.handleResetPassword(this.authActionService.actionCode!, password);
      this.authActionService.hasSucceeded = true;
    } catch (e: any) {
      console.log(e);
      this.matSnackBar.open(e.message, "OK");
    }
    this.authActionService.isLoadingResults = false;
  }


  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (!value) {
        return null; // don't validate empty values to allow optional controls
      }
      const regex = new RegExp('.*[^a-zA-Z\\d\\s:]+.*');
      const isValid = regex.test(value);

      if (isValid) {
        return null;
      }
      return {
        'passwordComplexity': 'Password must contain at least one special character that is not a letter, digit, whitespace, or colon.'
      };
    };
  }

  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }

  toggleConfirmPasswordVisibility() {
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }

}
