import { Component } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private gtmService: GoogleTagManagerService) {
    gtmService.addGtmToDom();
  }
  title = 'ClearScape Analytics Experience Web Console';

}

