import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.components.css', '../../shared/css/auth.css'],
})

export class VerifyEmailComponent implements OnInit {
  constructor(public authService: AuthService, private matSnackBar: MatSnackBar) {}
  isLoadingResults: boolean = false;

  ngOnInit() {
  }

  async sendVerificationMail() {
    this.isLoadingResults = true;
    this.authService.sendVerificationMail()
      .then(() => {
        this.matSnackBar.open("Confirmation email sent. Please check your inbox.", "OK");
      })
      .catch((e) => {
        this.matSnackBar.open(e.message, "OK");
      })
      .finally(()=>{
        this.isLoadingResults = false;
      });
  }
}
