import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-header-description-card',
  templateUrl: './icon-header-description-card.component.html',
  styleUrls: ['./icon-header-description-card.component.scss']
})
export class IconHeaderDescriptionCardComponent implements OnInit {

  @Input() icon: string = "teradata-icon.svg";
  @Input() header: string | undefined;
  @Input() description: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
