<div class="centered-content-standalone-canvas">
  <div class="centered-content-standalone normal-width">
    <div class="action-in-progress">
      <div *ngIf="this.authActionService.isLoadingResults">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
    <app-confirm-email-verification *ngIf="this.authActionService.mode === 'verifyEmail'">
    </app-confirm-email-verification>

    <app-reset-password *ngIf="this.authActionService.mode === 'resetPassword'">
    </app-reset-password>

  </div>
</div>
