
<div class="container">
  <div class="row">
    <div class="icon-container">
      <img src="./assets/{{icon}}" alt='icon image'/>
    </div>
  
  </div>
  
  <div class="row">
    <div class="title">{{header}}</div>

  <div class="subtitle">{{description}}</div>
  </div>
</div>
