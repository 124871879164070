import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Timestamp } from 'firebase/firestore';
import { AuthService } from '../../shared/services/auth.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['../../shared/css/auth.css', './sign-up.component.css'],
})

export class SignUpComponent implements OnInit {
  constructor(public authService: AuthService,
            private matSnackBar: MatSnackBar,
            private dialog: MatDialog,
            public router: Router,
            private gtmService: GoogleTagManagerService) {}
  isLoadingResults: boolean = false;

  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('.*[^a-zA-Z\d\s:]+.*')]);
  firstname = new FormControl('', [Validators.required]);
  lastname = new FormControl('', [Validators.required]);
  company = new FormControl('', [Validators.required]);
  emailMarketingConsent = false;
  termsAndConditionsConsent = false;
  hide = true;


  ngOnInit() {
    const state = window.history.state;
    this.email.setValue(state.email);
    this.password.setValue(state.password);
  }

  async signUp() {
    this.gtmService.pushTag({
      event: 'web_signup_attempt'
    })
    this.isLoadingResults = true;

    const createdAt = Timestamp.now();

    const profile = {
      firstname: this.firstname.value!,
      lastname: this.lastname.value!,
      company: this.company.value!,
      emailMarketingConsent: this.emailMarketingConsent,
      termsAndConditionsConsent: this.termsAndConditionsConsent,
      createdAt
    }

    this.authService.signUp(this.email.value!, this.password.value!, profile)
      .then(() => {
        this.gtmService.pushTag({
          event: 'web_signup_success'
        })
      })
      .catch((e) => {
        if (e.message.includes("auth/email-already-in-use")) {
          this.matSnackBar.open("Email already taken. Please use a different email address.", "OK");
        } else if (e.message.includes("auth/internal-error") && e.message.includes("403")) {
          console.log(e);
          this.matSnackBar.open("Email addresses subaddressed with \"+\" are not allowed", "OK");
        } else if (e.message.includes("auth/internal-error") && !e.message.includes("400")) {
          console.log(e);
          this.matSnackBar.open("Something went wrong. Please try again in a few minutes.", "OK");
        } else {
          this.matSnackBar.open(e.message, "OK");
        }
      })
      .finally(()=>{
        this.isLoadingResults = false;
      });
  }

  getErrorMessageForEmail() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.email.hasError('email')) {
      return 'You must enter a valid email address';
    }
    return '';
  }

  getErrorMessageForPassword() {
    if (this.password.hasError('required')) {
      return 'You must enter a value';
    }
    if (this.password.hasError('minlength')) {
      return 'Password must have minimum 8 characters';
    }
    if (this.password.hasError('pattern')) {
      return 'Password must have minimum 1 non-alphabetic character.';
    }
    return '';
  }

  getErrorMessageForFirstname() {
    if (this.firstname.hasError('required')) {
      return 'You must enter a value';
    }
    return '';
  }

  getErrorMessageForLastname() {
    if (this.lastname.hasError('required')) {
      return 'You must enter a value';
    }
    return '';
  }

  getErrorMessageForCompany() {
    if (this.company.hasError('required')) {
      return 'You must enter a value';
    }
    return '';
  }

  getErrorMessageForTermsAndConditions() {
    if (!this.termsAndConditionsConsent) {
      return 'You must accept Terms and Conditions';
    }
    return '';
  }

  isFormValid() {
    return !this.email.invalid &&
      !this.password.invalid &&
      !this.firstname.invalid &&
      !this.lastname.invalid &&
      !this.company.invalid &&
      this.termsAndConditionsConsent;
  }

  onKeyDown(event: any) {
    if (event.keyCode === 13 && this.isFormValid()) {
      this.signUp();
    }
  }

  showLicenseDialog() {
    const dialogRef = this.dialog.open(LicenseDialogComponent, {
      width: '770px',
      maxWidth: '770px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed with result: ' + JSON.stringify(result));
      if (result && result.approved && result.approved) {
        this.termsAndConditionsConsent = true;
      } else {
        this.termsAndConditionsConsent = false;
      }

    });
  }
}

@Component({
  selector: 'license-dialog',
  templateUrl: 'license.dialog.html',
  styleUrls: ['../../shared/css/auth.css', './license.dialog.css']
})
export class LicenseDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LicenseDialogComponent>
  ) {}
  isScrolledToEnd = false;

  onNoClick(): void {
   this.dialogRef.close();
  }

  onSubmit(): void {
    console.log(`onSubmit()`);
    this.dialogRef.close({
      approved: true
    });

  }

  onKeyDown(event: any) {
    if (event.keyCode === 13) {
      this.onSubmit();
    }
  }

  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1) {
      console.log('scrolled to the end');
      this.isScrolledToEnd = true;
    }
  }

}


