import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-slides',
  templateUrl: './slides.component.html',
  styleUrls: [     
    './slides.component.scss'
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SlidesComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }
}
