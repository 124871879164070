<section class="footer-section">

  <div class="footer-centered-container">

    <div class="column-left">

      <div class="footer-copyright-notice">
        ©2024 Teradata. All Rights Reserved
      </div>
    </div>

    <div class="column-right">

      <div class="footer-links">
        <a href="https://www.teradata.com/Privacy">Privacy</a>
        <a href="https://www.teradata.com/Privacy#do-not-sell-share">Don't sell / share my info</a>
        <a href="https://www.teradata.com/Legal/Terms-of-Use">Terms of Use</a>
        <a href="https://www.teradata.com/Legal/Legal-Notice">Legal</a>
        <a href="https://www.teradata.com/Product-Support-Policy">Support policies</a>
        <a (click)="showConsentForm()">Tracking Consent</a>
      </div>
    </div>

  </div>

</section>
