<h1 mat-dialog-title>Terms and Conditions</h1>
<div mat-dialog-content>
  <div id="license" class="content" (scroll)="onScroll($event)">
    <p autofocus>Teradata is providing you access to the ClearScape Analytics Experience solely for the FUNCTIONAL TESTING of ClearScape Analytics features, the results of which shall remain confidential to the parties and subject to any confidential agreements between the parties. You will not license, sublicense, sell, resell, rent, lease, transfer, assign, create derivative works of or distribute the ClearScape, in whole or in part. You will not reverse-engineer ClearScape Analytics Experience, except as expressly permitted by applicable law. ClearScape Analytics Experience remains the exclusive property of Teradata and its licensors.</p>

    <p>ClearScape Analytics Experience HAS NOT been set up to use with sensitive data, production data, or personally identifiable information. This service is designed for functional testing only. Performance testing of features requires a platform configured for that purpose. In particular, ClearScape Analytics Experience does not provide a secure environment that is certified compliant with GDPR, HIPAA, ISO 27001, PCI DSS, and SOC 1 and 2.</p>

    <p>You are solely responsible for the usage of ClearScape Analytics Experience and any resulting outcomes. ClearScape Analytics Experience is being provided to you on an 'AS IS' and 'AS AVAILABLE' basis.</p>

    <p>A monitoring program will connect to your environments periodically to determine if the environment is idle, collect basic usage statistics, and look for invalid logon attempts. We will not have access to the tables you create. We take no responsibility for the security of your environment or its contents since its intended purpose is for functional testing.</p>

    <p>A ClearScape Analytics Experience environment will be stopped when idle and may be started using the ClearScape Analytics Experience web console. An e-mail notification will be sent to you when the environment is about to be suspended.</p>

    <p>Teradata will impose limits on the number of environments you can create, the total hours of usage per month for all of your environments, and the length of time a given platform may be used. Information on those limits was communicated on the ClearScape Analytics Experience registration page and will be sent to you in a welcome e-mail when you provision an environment. You will see warning messages on your ClearScape Analytics Experience web console when you approach limits and a notification will be sent to you when a limit is approached.</p>
  </div>
</div>
<div mat-dialog-actions>
  <div class="action-buttons row center-vertically">
    <div class="left-align column">
      <button mat-button (click)="onNoClick()" class="cancel-button">Cancel</button>
    </div>
    <div class="right-align column">
      <button mat-flat-button
      (click)="onSubmit()" color="accent" type="submit">Agree</button>
    </div>
  </div>
</div>
