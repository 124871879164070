<div class="column reasons">
  <div class="centered-content column-content-width">
    <div class="product-name">ClearScape Analytics Experience</div>
    <div class="gain-access">Gain access to:</div>
    <ul class="gain-access-reasons">
      <li>Demos with ClearScape Analytics - advanced analytics and AI/ML capabilities to solve business problems</li>
      <li>A 30 GB Vantage database that can be used with BI/ETL and SQL GUI tools</li>
      <li>Sample data for use with demos and your own tools</li>
    </ul>
    <div class="cta-button"><button mat-flat-button type="submit" color="accent" (click)="signUpToday()">Sign up today</button></div>
    <div class="fineprint-message">The ClearScape Analytics Experience is limited to 40 hours per month. It may not be used for production or sensitive data. This small system is for functional testing only. For large data sets or performance evaluations, please <a href="https://www.teradata.com/About-Us/Contact">contact sales</a>.</div>
  </div>
</div>
