<div class="row layout">
  <app-auth-message class="registration-reasons"></app-auth-message>
  <div class="column content">
    <div class="centered-content extended-width">
      <div class="action-in-progress">
        <div *ngIf="isLoadingResults">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
      <h3 class="title">Create an account</h3>
      <div class="column-flow">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input id="email" matInput [formControl]="email" required (keydown)="onKeyDown($event)"/>
          <mat-error *ngIf="email.invalid">{{getErrorMessageForEmail()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input id="password" [type]="hide ? 'password' : 'text'" matInput type="password" [formControl]="password" required (keydown)="onKeyDown($event)" />
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="password.invalid">{{getErrorMessageForPassword()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>First name</mat-label>
          <input id="firstname" matInput [formControl]="firstname" required (keydown)="onKeyDown($event)" />
          <mat-error *ngIf="firstname.invalid">{{getErrorMessageForFirstname()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Last name</mat-label>
          <input id="lastname" matInput [formControl]="lastname" required (keydown)="onKeyDown($event)" />
          <mat-error *ngIf="lastname.invalid">{{getErrorMessageForLastname()}}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Company</mat-label>
          <input id="company" matInput [formControl]="company" required (keydown)="onKeyDown($event)" />
          <mat-error *ngIf="company.invalid">{{getErrorMessageForCompany()}}</mat-error>
        </mat-form-field>

        <div class="marketing-consent">
          <mat-checkbox id="email-marketing-consent" [(ngModel)]="emailMarketingConsent">I would like to receive updates on Teradata products, services, and events.</mat-checkbox>
          <mat-checkbox id="terms-and-conditions-consent" [(ngModel)]="termsAndConditionsConsent" (click)="showLicenseDialog()">I agree to <a>the terms and conditions</a>.</mat-checkbox>
        </div>

        <div class="action-buttons row center-vertically">
          <div class="left-align column">
            <button mat-button [routerLink]="'/'" class="cancel-button">Cancel</button>
          </div>
          <div class="right-align column">
            <button mat-flat-button type="submit" color="accent"
            (click)="signUp()" [disabled]="!isFormValid()">Create Account</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
